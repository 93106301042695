
import { IMAGE_BASE_URL } from '@/config'
import { defineComponent } from 'vue'
import useForm from '@/hooks/useForm'
export default defineComponent({
  setup () {
    return {
      ...useForm(),
      IMAGE_BASE_URL
    }
  }
})
