
import { IMAGE_BASE_URL } from '@/config'
import { defineComponent, ref, watch } from 'vue'
import { store } from '@/store'
export default defineComponent({
  setup () {
    const isNavOpen = ref(false)

    watch(
      () => isNavOpen.value,
      (value) => {
        if (value) {
          document.body.style.overflowY = 'hidden'
        } else {
          document.body.style.overflowY = 'auto'
        }
      }
    )

    const navArr = [
      'Как это работает?',
      'Для кого',
      'Кейсы',
      'Тарифы',
      'Безопасность'
    ]

    const langs = ['RU', 'EN']
    return {
      isNavOpen,
      store,
      langs,
      navArr,
      IMAGE_BASE_URL
    }
  }
})
