
import { IMAGE_BASE_URL } from '@/config'
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  data () {
    return {
      IMAGE_BASE_URL
    }
  },
  setup () {
    let id = 0

    const howData = [
      {
        desc: ['Подключите свой', 'банковский счет'],
        imgUrl: 'connect.png'
      },
      {
        desc: ['Выберите, куда и кому', 'отправлять информацию'],
        imgUrl: 'choise.png'
      },
      {
        desc: ['Получайте информацию', 'о поступивших оплатах'],
        imgUrl: 'note.png'
      }
    ].map((item) => {
      id++
      return {
        ...item,
        id
      }
    })

    onMounted(() => {
      howData.forEach((item) => {
        const element = document.getElementById(`desc-${item.id}`)

        if (element !== null) {
          element.innerHTML = item.desc.reduce((str, stroke) => {
            return str.length === 0 ? stroke : str + '</br>' + stroke
          }, '')
        }
      })
    })

    return {
      howData
    }
  }
})
