
import { defineComponent } from 'vue'
import { IMAGE_BASE_URL } from '@/config'

export default defineComponent({
  setup () {
    const data = [
      {
        title: 'Интеграции с CRM-системами Битрикс 24 и amoCRM',
        desc: 'Интегрируйте уведомления о платежах прямо в вашу CRM, в которой работают ваши сотрудники',
        img: ['cloud.png', 'crm.png']
      },
      {
        title: 'Открытое API Everypay',
        desc: 'Подключайте свои системы и интегрируйте уведомления о поступающих платежах самостоятельно с помощью простого API Everypay'
      },
      {
        title: 'Автоматическое создание платежек',
        desc: 'Загрузите скан счета на оплату в нашу систему и в банк-клиенте автоматически будет создано платежное поручение на основании данных из счета. С помощью технологий компьютерного зрения и машинного обучения мы достигаем высокой точности и скорости работы'
      }
    ]
    return {
      IMAGE_BASE_URL,
      data
    }
  }
})
