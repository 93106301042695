
import { defineComponent, onMounted, watch } from 'vue'
import { IMAGE_BASE_URL } from '@/config'
import SmallForm from './SmallForm.vue'
import { store } from '@/store'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default defineComponent({
  data () {
    return {
      IMAGE_BASE_URL,
      store
    }
  },
  components: { SmallForm, Carousel, Slide },
  setup () {
    let id = 0

    const orderData = [
      {
        header: 'Не пропускайте важные&nbsp;оплаты',
        desc: 'Если от них зависят ваши проекты или старт рекламных кампаний',
        imgUrl: 'money.png'
      },
      {
        header: 'Сократите издержки на&nbsp;бухгалтерию и&nbsp;коммуникации',
        desc: 'Получайте информацию о платежах на счет компании там, где вам удобно',
        imgUrl: 'pig.png'
      },
      {
        header: 'Создавайте собственные сценарии действий',
        desc: 'при поступлении денег на счет вашей компании',
        imgUrl: 'screen.png'
      },
      {
        header:
          'Реагируйте на&nbsp;платежи так&nbsp;быстро, как&nbsp;они поступают на&nbsp;ваш&nbsp;счет',
        desc: null,
        imgUrl: 'mobile.png'
      }
    ].map((item) => {
      id++
      return {
        ...item,
        id
      }
    })

    const fillTitle = () => {
      orderData.forEach((item) => {
        const element = document.getElementById(`title-${item.id}`)
        if (element) {
          element.innerHTML = item.header
        }
      })
    }

    onMounted(() => {
      fillTitle()
    })

    window.addEventListener('resize', () => fillTitle())

    return {
      orderData
    }
  }
})
