
import { IMAGE_BASE_URL } from '@/config'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { store } from '@/store'

export default defineComponent({
  data () {
    return {
      IMAGE_BASE_URL
    }
  },
  setup () {
    let id = 0

    const data = ref(
      [
        {
          title: 'Малый бизнес',
          desc: [
            'Скорость получения',
            'информации о платежах',
            'и минимизация издержек'
          ],
          imgUrl: 'tree.png'
        },
        {
          title: 'Стартапы',
          desc: ['Автоматизация', 'бизнес-процессов', 'и бухгалтерской рутины'],
          imgUrl: 'pony.png'
        },
        {
          title: 'Разработчики',
          desc: [
            'Быстрое внедрение своих',
            'приложений для малого',
            'бизнеса и стартапов'
          ],
          imgUrl: 'lamp.png'
        }
      ].map((item) => {
        id++
        return {
          ...item,
          id
        }
      })
    )

    onMounted(() => {
      data.value.forEach((item) => {
        const element = document.getElementById(`for-whom-desc-${item.id}`)
        if (element) {
          element.innerHTML = item.desc.reduce((str, stroke) => {
            return str.length > 0 ? str + '</br>' + stroke : stroke
          }, '')
        }
      })
    })
    watch(
      () => store.isDesktop,
      (value) => {
        if (!value && data.value[2]) {
          data.value[2].desc = [
            'Быстрое внедрение',
            'своих приложений',
            'для малого бизнеса',
            'и стартапов'
          ]
        }
      },
      { immediate: true }
    )

    return {
      data
    }
  }
})
